<template>
  <div id="index">
    <headerEl />
    <main id="wrap" class="has-width-limit">
      <form v-if="(jsTag && jsTag.formDesign.updaterid) || !jsTag">
        <div id="reserve-vue">
          <div class="step-header block">
            <div class="block-head">
              <h2>ご予約はカンタン3ステップ！</h2>
            </div>
            <div class="block-body">
              <div class="unit is-current">
                <p>クリニック・日時を<br class="mq" />選ぶ</p>
              </div>
              <div class="unit">
                <p>患者さま情報の<br class="mq" />ご入力</p>
              </div>
              <div class="unit">
                <p>患者さま情報の<br class="mq" />ご確認</p>
              </div>
            </div>
          </div>
          <div class="minor-area block">
            <div class="block-body">
              <h2 style="text-align: center">未成年者のご契約について</h2>
              <p>
                未成年（16歳以上18歳未満）の方は、ご契約にあたり親権者同意書が必要です。<br />
                なお、18歳を迎えていても高校在学中の方は親権者さまの同意書が必要となります。<br />
                <span
                  @click="opentest()"
                  style="cursor: pointer; color: #0000ee"
                  >（親権者同意書はこちら）</span
                >
              </p>
              <h2 style="text-align: center; margin-top: 8px">
                ペア予約について
              </h2>
              <p>
                代表者さまのカウンセリング予約完了後、<br />
                ご予約完了ページにあります「ペア申し込みはこちら」から<br />
                同伴者さま情報のご入力をお願いいたします。
              </p>
              <!-- <h2 style="text-align: center; margin-top: 8px; color: #ff882f">
                キレイ応援キャンペーン中！
              </h2>
              <p style="color: #333">
                全身医療脱毛新規ご契約で、<br />
                「全身キレイプログラム」を無料プレゼント！<br />
                詳しくはカウンセリング時に<br />
                ご説明させていただきます。
              </p> -->
            </div>
            <!-- <img
              src="@/assets/images/shouyePc.jpg"
              alt=""
              class="minor_area_img"
              v-if="IsPC"
            />
            <img
              src="@/assets/images/shouye.jpg"
              alt=""
              class="minor_area_img"
              v-else
            /> -->
          </div>
          <section class="store-area">
            <div
              :style="{ display: errMsg ? 'block' : 'none' }"
              class="popup"
              id="popup_r_limit"
            >
              <p id="popup_r_content" v-html="errMsg"></p>
              <div class="popup-btn-group">
                <a href="#" class="popup-btn" @click="errMsg = false">戻る</a>
              </div>
            </div>

            <div class="section-head">
              <h2>クリニック・日時を選択してください</h2>
            </div>
            <div id="choose-area" class="section-body">
              <div class="block cols">
                <div class="block-head col">
                  <h3><i class="icon marker"></i>ご希望エリア</h3>
                </div>
                <div class="block-body col">
                  <label class="select-box">
                    <select @change="getAreaInfo" id="area_select">
                      <option value="" selected="selected">
                        選択してください
                      </option>
                      <optgroup
                        :label="item.name"
                        v-for="(item, index) in areaList"
                        :key="index + 'arealist'"
                      >
                        {{ item.name }}
                        <option
                          v-for="(item1, index1) in item.list"
                          :key="index1 + 'list'"
                          :value="item1.remarks1"
                        >
                          {{ item1.remarks1 }}
                        </option>
                      </optgroup>
                    </select>
                  </label>
                </div>
              </div>
              <div
                id="store_select"
                class="block cols last"
                style="display: block"
              >
                <div class="block-head">
                  <h3><i class="icon store"></i>ご希望のクリニック</h3>
                </div>
                <div class="block-body store_list">
                  <div
                    v-for="(item, index) in clinicList"
                    :key="index"
                    class="radio-button"
                  >
                    <label
                      ><input
                        type="radio"
                        name="store"
                        class="store_select"
                        :value="item.name"
                      /><span class="tb-1" @click="changeStore(item, index)"
                        ><span class="tb-2"> {{ item.name }}</span></span
                      ></label
                    >
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div id="reserve_selectdate" style="display: block">
            <p
              v-if="calendarShow"
              id="ReserveNotice"
              class="reserve-notice"
              style="display: block"
            >
              アリシアクリニックでは照射枠を確保するため、<br />
              各クリニックでお受けできるカウンセリング予約数に制限がございます。
            </p>
            <div v-if="calendarShow" class="reserve-card block" style="">
              <div class="block-head cols">
                <div class="col">
                  <p>ご予約のクリニック</p>
                  <h2 id="reserve_selectstore">{{ shopFreeTime.shopName }}</h2>
                </div>
                <div class="col">
                  <div class="tb-1">
                    <div class="tb-2"></div>
                  </div>
                </div>
              </div>
              <div class="block-body">
                <p id="shop-address">
                  {{ shopFreeTime.prefecture + shopFreeTime.city }}<br />
                  {{
                    shopFreeTime.cityAddr1 +
                    shopFreeTime.cityAddr2 +
                    shopFreeTime.cityAddr3
                  }}
                </p>
                <div
                  id="available_reserved_space"
                  style=""
                  v-if="status != 'OFF'"
                >
                  <br />
                  <p v-if="status != 'OFF'" class="available_reserved_button">
                    <b
                      >予約可能数：
                      <span id="available_reserved_count">{{ overCount }}</span
                      >件</b
                    >
                  </p>
                </div>
              </div>
            </div>
            <section v-if="calendarShow" class="calender-section">
              <div class="section-body">
                <div class="calender">
                  <div class="calender-head cols">
                    <div class="col" @click="preCalendar">
                      <span
                        :class="{
                          'before-week arrow-small-button left': true,
                          'is-disabled': !back,
                          bt: back,
                        }"
                      >
                        <span class="tb-1">
                          <span class="tb-2">前週</span>
                        </span>
                      </span>
                    </div>
                    <div class="col">
                      <div class="tb-1">
                        <div class="tb-2">
                          <h2 id="target-date">{{ currentWeek }}</h2>
                        </div>
                      </div>
                    </div>
                    <div class="col" @click="nextCalendar">
                      <span class="next-week arrow-small-button bt right">
                        <span class="tb-1">
                          <span class="tb-2">翌週</span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div>
                    <p class="calender-remarks">
                      お好きなカウンセリングスタート時間をお選びください。
                    </p>
                  </div>
                  <div class="calender-body">
                    <table>
                      <thead>
                        <tr id="calender-month-header">
                          <td
                            v-if="preWeekWidth"
                            :colspan="preWeekWidth"
                            align="center"
                          >
                            {{ preMonth }}月
                          </td>
                          <td
                            v-if="currentWeekWidth"
                            :colspan="currentWeekWidth"
                            align="center"
                          >
                            {{ nextMonth }}月
                          </td>
                        </tr>
                        <tr>
                          <td
                            v-for="(item, index) in daysList"
                            :key="'days' + index"
                            :style="{
                              'background-color':
                                item.showDayOfWeek == '(土)'
                                  ? 'rgb(217, 227, 236)'
                                  : item.showDayOfWeek == '(日)' ||
                                    item.isHoliday
                                  ? 'rgb(238, 221, 217)'
                                  : 'rgb(255, 255, 255)',
                            }"
                          >
                            <p
                              id="cal-head-col0"
                              :class="{
                                weekday: true,
                                saturday: item.showDayOfWeek == '(土)',
                                sunday:
                                  item.showDayOfWeek == '(日)' ||
                                  item.isHoliday,
                              }"
                            >
                              {{ +item.showDayOnly }}<br />{{
                                item.showDayOfWeek
                              }}
                            </p>
                          </td>
                        </tr>
                      </thead>
                      <tbody id="cal-body">
                        <tr
                          class="table-list"
                          v-for="(item, index) in dayArr"
                          :key="index + 'dayarr'"
                        >
                          <template v-for="(item1, index1) in item.tr">
                            <td
                              v-if="!item1.isFreetime"
                              class="closeCell"
                              :key="index1 + 'no'"
                            >
                              <div
                                class="ngMark"
                                :style="{
                                  color:
                                    item1.showDayOfWeek == '(土)'
                                      ? '#4466bb'
                                      : item1.showDayOfWeek == '(日)' ||
                                        item1.isHoliday
                                      ? '#bb5555'
                                      : '',
                                }"
                              >
                                ×
                              </div>
                            </td>
                            <td
                              @click="urltoInput(item1)"
                              v-else
                              :class="{
                                'bt ok_td': true,
                                saturday: item1.showDayOfWeek == '(土)',
                                sunday:
                                  item1.showDayOfWeek == '(日)' ||
                                  item1.isHoliday,
                              }"
                              :key="index1 + 'yes'"
                            >
                              <div class="timeMark">{{ item1.startTime }}</div>
                              <div class="okMark">◎</div>
                            </td>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="calender-foot cols">
                    <div class="col" @click="preCalendar">
                      <span
                        :class="{
                          'before-week arrow-small-button left': true,
                          'is-disabled': !back,
                          bt: back,
                        }"
                      >
                        <span class="tb-1">
                          <span class="tb-2"> 前週 </span>
                        </span>
                      </span>
                    </div>
                    <div class="col" @click="nextCalendar">
                      <span class="next-week arrow-small-button bt right">
                        <span class="tb-1">
                          <span class="tb-2"> 翌週 </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </form>
      <div v-else style="margin: 60px 10px">
        <p style="font-size: 150%">不正なアクセスです。</p>
      </div>
    </main>
    <errorMsgTwo
      :errMsgShow.sync="modalShow"
      :errMsg="'翌週に移動できません。'"
    />
    <footerEl />
  </div>
</template>

<script>
import { indexApi } from "@/api/module.js";
import headerEl from "@/components/header.vue";
import footerEl from "@/components/footer.vue";
import errorMsgTwo from "@/components/errorMsgTwo.vue";
import { mapState } from "vuex";
import $ from "jquery";

export default {
  data() {
    return {
      errMsgShow: false,
      errMsg: "",
      areaList: [],
      clinicList: [],
      remarks1: "選択してください",
      shopName: "",
      calendarShow: false,
      groupReserveLimit: {},
      shopFreeTime: {},
      formDate: "",
      currentWeek: "",
      daysList: [],
      beforeWeekDate: "",
      nextWeekDate: "",
      currentDate: "", // 今天的时间
      overCount: 0,
      k: "",
      back: true,
      status: "OFF",
      preWeekWidth: 0,
      currentWeekWidth: 0,
      preMonth: "",
      nextMonth: "",
      shopCode: null,
      dayArr: [],
      agreebook: "https://www.aletheia-clinic.com/price/minor/",
      modalShow: false,

      nextMonthNum: 0, //记录预约翻页次数
    };
  },
  components: { headerEl, footerEl, errorMsgTwo },
  computed: {
    ...mapState([
      "completeInfo",
      "completeRes",
      "userInfo",
      "jsTag",
      "IsPC",
      "xuid",
      "suid",
    ]),
  },
  mounted() {
    if (this.$route.query.suid) {
      this.$store.commit("updateSuid", this.$route.query.suid);
    } else {
      this.$store.commit("updateSuid", "");
    }
    if (this.$route.query.xuid) {
      this.$store.commit("updateXuid", this.$route.query.xuid);
    } else {
      this.$store.commit("updateXuid", this.$route.query.xuid);
    }
    this.getAreaList();
    this.getCurrentDate();
    this.getHeader();
  },
  methods: {
    getHeader() {
      if (this.$route.query.k) {
        this.k = this.$route.query.k;
        indexApi
          .header({
            k: this.k,
          })
          .then((res) => {
            this.$store.commit("updateJsTag", res.data);
            if (
              res.data.coReserveForm &&
              res.data.coReserveForm.headerTagInSearch
            ) {
              this.$store.commit("addTag", {
                headStr: res.data.coReserveForm.headerTagInSearch,
                bodyStr: res.data.coReserveForm.tagInSearch,
              });
            }
          });
      } else {
        this.$store.commit("updateJsTag", null);
      }
    },
    getCurrentDate() {
      let date = new Date();
      let month = +date.getMonth() + 1;
      let day = date.getDate();
      month < 10 ? (month = "0" + month) : month;
      day < 10 ? (day = "0" + day) : day;
      this.formDate = date.getFullYear() + "-" + month + "-" + day;
      this.currentDate = this.formDate;
    },
    urltoInput(info) {
      if (info.isFreetime) {
        let completeInfo = {
          configCode: this.k,
          areaname: this.remarks1, // 区域名称
          shopName: this.shopName, // 诊所名称
          reserveDate: info.date, // 预约日期
          reserveTime: info.startTime, // 预约开会时间
          reserveEndTime: info.endTime,
          referer: "[" + window.location.href + "]",
          shopCode: this.shopCode,
        };
        this.$store.commit("updateCompleteInfo", completeInfo);
        this.$store.commit("updateCompleteRes", null);
        this.$store.commit("updateUserInfo", null);
        this.$router.push("/Reservations/input");
      }
    },
    getAreaInfo(e) {
      this.calendarShow = false;
      this.errMsgShow = false;
      this.clinicList = [];
      this.remarks1 = e.target.value;
      this.getClinicList();
    },
    getAreaList() {
      indexApi.areaList().then((res) => {
        this.areaList = res.data;
      });
    },
    getClinicList() {
      indexApi.clinicList({ remarks1: this.remarks1 }).then((res) => {
        this.clinicList = res.data;
        this.clinicList.forEach((item) => {
          item.selected = false;
        });
        $("html, body").animate(
          {
            scrollTop: $("#reserve_selectdate")[0].offsetTop,
          },
          { duration: 500, easing: "swing" }
        );
      });
    },
    look_rule() {
      window.open("https://www.aletheia-clinic.com/price/minor/");
    },
    opentest() {
      window.open("https://www.aletheia-clinic.com/price/minor/");
    },
    changeStore(item, index) {
      this.nextMonthNum = 0;
      this.calendarShow = false;
      this.clinicList.forEach((item) => {
        item.selected = false;
      });
      this.clinicList[index].selected = true;
      this.clinicList = JSON.parse(JSON.stringify(this.clinicList));
      this.shopName = item.name;
      this.getCalendar();
    },
    getCalendar(formDate) {
      let data;
      formDate
        ? (data = {
            name: this.shopName,
            formDate: this.formDate,
          })
        : (data = {
            name: this.shopName,
          });
      indexApi.calendar(data).then((res) => {
        if (res.code == 500 || res.code == -1) {
          this.showError(res.data.message);
        } else {
          this.errMsgShow = false;
          let status = res.data.groupReserveLimit.status;
          let overCount = res.data.groupReserveLimit.overCount;
          let stopMsg = res.data.groupReserveLimit.reserveStopMsg;
          this.shopCode = res.data.shopFreeTime.shopCode;
          this.status = status;
          // stop 提示
          if (status == "STOP") {
            // alert(errMsg);
            this.showError(stopMsg);
          } else {
            if (status == "LIMIT" && overCount < 1) {
              // 预约数不足提示
              this.showError(stopMsg);
              return;
            }
            // 是否可点击上一周按钮
            if (res.data.shopFreeTime.fromDate == this.currentDate) {
              this.back = false;
            } else {
              this.back = true;
            }
            // 时间表 渲染数据
            this.groupReserveLimit = res.data.groupReserveLimit;
            this.overCount = this.groupReserveLimit.overCount;
            this.shopFreeTime = res.data.shopFreeTime;
            this.calendarShow = true;
            let daysList = res.data.shopFreeTime.daysList;

            this.currentWeek =
              daysList[0].showDate + "~" + daysList[6].showDate;
            // 计算上月 下月天数
            let days = +daysList[6].showDayOnly;
            if (days >= 7) {
              this.preWeekWidth = 0;
              this.currentWeekWidth = 7;
            } else {
              this.preWeekWidth = 7 - days;
              this.currentWeekWidth = days;
            }
            this.preMonth = +daysList[0].showMonthOnly.slice(
              0,
              daysList[0].showMonthOnly.length - 1
            );
            this.nextMonth = +daysList[6].showMonthOnly.slice(
              0,
              daysList[6].showMonthOnly.length - 1
            );
            this.beforeWeekDate = res.data.shopFreeTime.beforeWeekDate;
            this.nextWeekDate = res.data.shopFreeTime.nextWeekDate;

            this.daysList = daysList.slice(0, daysList.length - 1);
            let dayArr = [];
            daysList[0].freetimeList.forEach((item, index) => {
              dayArr.push({ tr: [] });
              daysList.forEach((item1, index1) => {
                if (index1 < 7) {
                  console.log(item1.freetimeList[index], item1.date);
                  if (item1.freetimeList[index]) {
                    item1.freetimeList[index].date = item1.date;
                    item1.freetimeList[index].showDayOfWeek =
                      item1.showDayOfWeek;
                    item1.freetimeList[index].isHoliday = item1.isHoliday;
                    dayArr[index].tr.push(item1.freetimeList[index]);
                  }
                }
              });
            });
            this.dayArr = dayArr;
            if (this.IsPC) {
              try {
                setTimeout(function () {
                  $("html, body").animate(
                    {
                      scrollTop: $("#ReserveNotice")[0].offsetTop + 93,
                      // - $("header")[0].clientHeight - 20,
                    },
                    { duration: 500, easing: "swing" }
                  );
                }, 0);
              } catch (err) {
                console.log(err, "err");
              }
            } else {
              try {
                setTimeout(function () {
                  $("html, body").animate(
                    {
                      scrollTop: $("#ReserveNotice")[0].offsetTop + 63,
                      // - $("header")[0].clientHeight - 20,
                    },
                    { duration: 500, easing: "swing" }
                  );
                }, 0);
              } catch (err) {
                console.log(err, "err");
              }
            }
          }
        }
      });
    },
    showError(msg) {
      this.errMsgShow = true;
      this.errMsg = msg;
    },
    preCalendar() {
      this.nextMonthNum = 0;
      if (this.back) {
        this.formDate = this.beforeWeekDate;
        this.getCalendar(this.formDate);
      } else {
        alert("前週に移動できません。");
      }
    },
    nextCalendar() {
      // 限制只允许预约两周之内的时间
      if (this.nextMonthNum == 1) {
        this.modalShow = true;
        return;
      } else {
        this.nextMonthNum = 1;
      }
      this.formDate = this.nextWeekDate;
      this.getCalendar(this.formDate);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/lib.scss";
</style>