<template>
  <!-- <div class="header">
    <header>
      <div class="header_container">
        <div class="header_top">
          <img class="header_icon" src="@/assets/home/head_icon.png" alt="" />
          <img
            class="header_icon_mobile"
            src="@/assets/home/head_icon_mobile.png"
            alt=""
          />
          <div v-html="title" class="header_title"></div>
        </div>
      </div>
    </header>
  </div> -->
  <header id="g-header">
    <div class="inner has-width-limit">
      <h1>
        <img
          src="@/assets/images/aletheia_logo2.png"
          alt="アリシアクリニック"
          class="logo-header"
        />
      </h1>
      <p v-html="title"></p>
    </div>
  </header>
</template>
<script>
export default {
  props: {
    title: {
      default: "無料カウンセリング <br class='mq' />予約フォーム",
    },
  },
  methods: {
    closeMsg() {
      this.$emit("update:errMsgShow", false);
    },
  },
};
</script>
<style lang="scss" scoped>

</style>