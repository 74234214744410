<template>
  <footer id="g-footer" class="block">
    <div class="block-body">
      <a href="" @click="openurl()">
        <img
          src="@/assets/images/aletheia_logo2.png"
          alt="アリシアクリニック"
          class="logo-footer"
        />
      </a>
    </div>
    <div class="block-foot">
      <p id="copyright">copyright© ALETHEIA CLINIC All Rights Reserved.</p>
    </div>
  </footer>
  <!-- <div class="footer">
    <footer>
      <div class="footer_top">
        <a href="https://www.aletheia-clinic.com/" target="_blank">
          <img class="header_icon btn-hover" src="@/assets/home/head_icon.png" alt="" />
        </a>
        <a href="https://www.aletheia-clinic.com/" target="_blank">
          <img class="footer_logo" src="@/assets/home/footer_logo.png" alt="" />
        </a>
      </div>
      <div class="foot_bottom">
        Copyright© ALETHEIA CLINIC All Rights Reserved.
      </div>
    </footer>
  </div> -->
</template>
<script>
export default {
  methods: {
    openurl(){
      window.open('https://www.aletheia-clinic.com')
    }
  },
}
</script>
<style lang="scss" scoped>

</style>